<template>
  <g>
    <svg:style>
      .cls-44,
      .cls-5,
      .cls-6 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        fill: url(#glass-pattern);
      }
    </svg:style>
    <g id="B08">
      <polyline
        id="glass"
        class="cls-44"
        :points="`${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 116.99} ${doorLeftWidth1 + 65.54} ${doorTopHeight1 + 116.99} ${doorLeftWidth1 + 65.54} ${doorTopHeight1 + 59.99} ${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 59.99} ${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 116.99}`"/>
      <polyline
        id="glass-2"
        data-name="glass"
        class="cls-5"
        :points="`${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 178.9} ${doorLeftWidth1 + 65.54} ${doorTopHeight1 + 178.9} ${doorLeftWidth1 + 65.54} ${doorTopHeight1 + 121.9} ${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 121.9} ${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 178.9}`"/>
      <polyline
        id="glass-3"
        data-name="glass"
        class="cls-6"
        :points="`${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 240.82} ${doorLeftWidth1 + 65.54} ${doorTopHeight1 + 240.82} ${doorLeftWidth1 + 65.54} ${doorTopHeight1 + 183.82} ${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 183.82} ${doorLeftWidth1 + 40.09} ${doorTopHeight1 + 240.82}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  },
}
</script>
